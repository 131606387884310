import React from "react";
import ReactDOM from "react-dom";
import { DAppProvider } from "@usedapp/core";
import { Provider } from "react-redux";
import { ModalProvider } from "react-modal-hook";
import { HelmetProvider } from "react-helmet-async";
import { createWeb3ReactRoot, Web3ReactProvider } from "web3-react-core";
import App from "./App";
import store from "./state";
import getLibrary from "./utils/getLibrary";
import { ThemeProvider } from "./contexts/ThemeContext";
import { RefreshProvider } from "./contexts/RefreshContext";

const Web3ProviderNetwork = createWeb3ReactRoot("NETWORK");

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <Web3ProviderNetwork getLibrary={getLibrary}>
          <ModalProvider>
            <DAppProvider config={{}}>
              <ThemeProvider>
                <RefreshProvider>
                  <HelmetProvider>
                    <App />
                  </HelmetProvider>
                </RefreshProvider>
              </ThemeProvider>
            </DAppProvider>
          </ModalProvider>
        </Web3ProviderNetwork>
      </Web3ReactProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
