/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { ConfigState } from "../types";
import { Dispatch } from "redux";
import { verifyService } from "../../services";
import _ from "lodash";

const initialState: ConfigState = {
  dataConfig: {},
  isVerified: false,
  showRegistered: false,
  loading: true,
  allItems: [],
  registeredCount: 0,
  signature: localStorage.getItem("s"),
  resultRaffle: false,
  ticketId: ""
};

export const configSlice = createSlice({
  name: "Config",
  initialState,
  reducers: {
    setConfig: (state, action) => {
      const { dataConfig, showRegistered, loading, allItems } = action.payload;
      state.dataConfig = dataConfig;
      state.showRegistered = showRegistered;
      state.loading = loading;
      state.allItems = allItems;
    },
    setRegisteredCount: (state, action) => {
      const { registeredCount } = action.payload;
      state.registeredCount = registeredCount;
    },
    setSigNature: (state, action) => {
      const { signature } = action.payload;
      state.signature = signature;
    },
    setResultRaffle: (state, action) => {
      const { resultRaffle } = action.payload;
      state.resultRaffle = resultRaffle;
    },
    setTicketId: (state, action) => {
      const { ticketId } = action.payload;
      state.ticketId = ticketId;
    }
  }
});

// Actions
export const {
  setConfig,
  setRegisteredCount,
  setSigNature,
  setResultRaffle,
  setTicketId
} = configSlice.actions;

export const setSignMessage = (sign: string) => async (dispatch: Dispatch) => {
  return dispatch(setSigNature({ signature: sign }));
};

export const setTicket = (id: string) => async (dispatch: Dispatch) => {
  return dispatch(setTicketId({ ticketId: id }));
};

export const fetchConfig =
  (whitelistId: string, account?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    let dataConfigs: {} = {};
    let showRegistered: boolean = false;
    let loading: boolean = true;
    let allItems: any[] = [];

    await verifyService
      .whitelistConfigService(whitelistId)
      .then(async (res) => {
        dataConfigs = _.get(res, "data.data.config");
        const verifyChannels = _.get(res, "data.data.config.verifyChannels");
        if (account) {
          loading = false;
          const parsedData = await Promise.all(
            verifyChannels.map((item: any) =>
              verifyService
                .verifiedService(whitelistId, account || "", item.verifyId)
                .then(async (res) => {
                  if (_.get(res, "status") === 200) {
                    return _.get(res, "data.data.isVerified");
                  }
                })
                .catch((err) => {
                  console.error(err);
                })
            )
          );
          verifyChannels.forEach((item: any, i: any) => {
            item.show = parsedData[i];
          });

          if (parsedData.length > 0) {
            showRegistered = parsedData.every((o) => o === true);
          }
          allItems = verifyChannels;
        } else {
          loading = false;
        }
      })
      .catch(() => {});
    dispatch(
      setConfig({
        loading: loading,
        allItems: allItems,
        showRegistered: showRegistered,
        dataConfig: dataConfigs
      })
    );
  };

export const fetchRegisteredCount =
  (whitelistId: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    let count: number = 0;

    await verifyService
      .registeredCountService(whitelistId)
      .then(async (res) => {
        if (_.get(res, "status") === 200) {
          count = _.get(res, "data.data.registeredCount");
        }
      })
      .catch(() => {});

    dispatch(
      setRegisteredCount({
        registeredCount: count
      })
    );
  };

export const fetchResultRaffle =
  (whitelistId: string, walletAddress: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    let results: any = null;

    await verifyService
      .resultedService(whitelistId, walletAddress)
      .then(async (res) => {
        if (_.get(res, "status") === 200) {
          results = _.get(res, "data.data.result");
        }
      })
      .catch(() => {});

    dispatch(
      setResultRaffle({
        resultRaffle: results
      })
    );
  };

export default configSlice.reducer;
