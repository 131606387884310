import React from "react";
interface IThemeContext {
  dark: boolean;
  toggleDark?: () => void;
}

const defaultState = {
  dark: false
};

export const ThemeContext = React.createContext<IThemeContext>(defaultState);

export const ThemeProvider: React.FC = ({ children }) => {
  const [dark, setDark] = React.useState(defaultState.dark);

  const toggleDark = () => {
    setDark(!dark);
  };

  return (
    <ThemeContext.Provider
      value={{
        dark,
        toggleDark
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
