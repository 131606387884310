import axios from "axios";
import queryString from "querystring";

const getValue = (uuid: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/kvstore/redis` + `?key=${uuid}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const botVerify = (sig: any, data: any, id: any) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BOT_URL}/verify`, {
        sig: sig.cleanSig,
        userId: data.userId,
        guildId: data.serverId,
        connectNetworkId: sig.connectNetworkId,
        uuid: id
      })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const verifyNft = (
  whitelistId: string,
  flow: number,
  sourceRef: string,
  nftId?: number
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/whitelist/verify` +
          `?whitelistId=${whitelistId}&flow=${flow}&sourceRef=${sourceRef}${
            nftId !== undefined ? `&nftId=${nftId}` : ""
          }`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const registerNft = (
  whitelistId: string,
  flowId: number,
  verifyRef: string,
  email: string,
  distAddress: string,
  sourceAddress?: string,
  nftId?: number,
  sign?: object
) => {
  return new Promise((resolve, reject) => {
    if (nftId === -1 || nftId === undefined) {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/whitelist/register` +
            `?key=${process.env.REACT_APP_REDIS}`,
          {
            whitelistId: whitelistId,
            flowId: flowId,
            verifyRef: verifyRef,
            email: email,
            sourceAddress,
            distAddress: distAddress,
            sign
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/whitelist/register` +
            `?key=${process.env.REACT_APP_REDIS}`,
          {
            whitelistId: whitelistId,
            flowId: flowId,
            verifyRef: verifyRef,
            nftId,
            email: email,
            sourceAddress,
            distAddress: distAddress,
            sign
          }
        )
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    }
  });
};

const getWhitelist = (whitelistId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/whitelist/config` +
          `?whitelistId=${whitelistId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getTokenDiscord = (code?: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_DISCORD_URL}`,
        queryString.stringify({
          client_id: process.env.REACT_APP_CLIENT_ID,
          client_secret: process.env.REACT_APP_CLIENT_SECRET,
          grant_type: process.env.REACT_APP_GRANT_TYPE,
          code: code,
          redirect_uri: process.env.REACT_APP_REDIRECT_URI
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const verifyTwitter = (
  code?: any,
  account?: any,
  whitelistId?: any,
  verifyId?: any
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_VERIFY_URL}/api/whitelist/verify/twitter`,
        {
          whitelistId: whitelistId,
          verifyId: verifyId,
          walletAddress: account,
          accessToken: code
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const channelVerifyService = (
  whitelistId: string,
  verifyId: string,
  walletAddress: string,
  accessToken: string,
  channel: string
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_VERIFY_URL}/api/whitelist/verify/${channel}`,
        {
          whitelistId,
          verifyId,
          walletAddress,
          accessToken
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const registeredCountService = (whitelistId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_VERIFY_URL}/api/whitelist/registered/count` +
          `?whitelistId=${whitelistId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const whitelistConfigService = (whitelistId: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_VERIFY_URL}/api/whitelist/config` +
          `?whitelistId=${whitelistId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const verifiedService = (
  whitelistId: string,
  walletAddress: string,
  verifyId: string
) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_VERIFY_URL}/api/whitelist/verified/check` +
          `?whitelistId=${whitelistId}&walletAddress=${walletAddress}&verifyId=${verifyId}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const resultedService = (whitelistId: string, walletAddress: string) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_API_VERIFY_URL}/api/whitelist/result/check` +
          `?whitelistId=${whitelistId}&walletAddress=${walletAddress}`
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const getQrcode = (whitelistId: string, signature: string) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_API_VERIFY_URL}/api/whitelist/get/ticketId`,
        {
          whitelistId,
          signature
        }
      )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const verifyService = {
  getValue,
  botVerify,
  verifyNft,
  registerNft,
  getWhitelist,
  getTokenDiscord,
  verifyTwitter,
  channelVerifyService,
  registeredCountService,
  whitelistConfigService,
  verifiedService,
  resultedService,
  getQrcode
};
