import { useEffect, useMemo } from "react";
import { State } from "./types";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React } from "web3-react-core";
import {
  fetchNFTUser,
  fetchlanguage,
  fetchConfig,
  fetchRegisteredCount,
  fetchResultRaffle
} from "./actions";
import useRefresh from "../hooks/useRefresh";

export const useDisconnect = (): boolean => {
  const isDisconnect = useSelector((state: State) => state.wallet.disconnect);
  return isDisconnect;
};

export const useCurrentAccountState = (): string => {
  const currentAccount = useSelector((state: State) => state.wallet.account);
  return currentAccount;
};

export const useOpenDialogWallet = (): boolean => {
  const openDialogWallet = useSelector(
    (state: State) => state.wallet.openDialogWallet
  );
  return openDialogWallet;
};

export const useDataConfig = () => {
  const dataConfig = useSelector((state: State) => state.config.dataConfig);
  const {
    loading,
    signature,
    registeredCount,
    showRegistered,
    allItems,
    resultRaffle,
    ticketId
  } = useSelector((state: State) => state.config);
  const active = _.get(dataConfig, "active");
  const profileName = _.get(dataConfig, "profileName");
  const profileImage = _.get(dataConfig, "profileImage");
  const description = _.get(dataConfig, "description");
  const url = _.get(dataConfig, "url");
  const instructions = _.get(dataConfig, "instructions");
  const remark = _.get(dataConfig, "remark");
  const startDate = _.get(dataConfig, "period.startDate");
  const endDate = _.get(dataConfig, "period.endDate");
  const userMaximum = _.get(dataConfig, "userMaximum");
  const bannerUrl = _.get(dataConfig, "bannerUrl");
  const whitelistID = _.get(dataConfig, "whitelistId");
  const walletTypeConnect = _.get(dataConfig, "walletTypeConnect");
  const chainName = _.get(dataConfig, "chainName");
  const raffle = _.get(dataConfig, "raffle");
  const ticketGen = _.get(dataConfig, "ticketGen");
  return {
    active,
    profileName,
    profileImage,
    description,
    url,
    instructions,
    remark,
    startDate,
    endDate,
    userMaximum,
    bannerUrl,
    loading,
    whitelistID,
    walletTypeConnect,
    signature,
    registeredCount,
    chainName,
    showRegistered,
    allItems,
    raffle,
    resultRaffle,
    ticketGen,
    ticketId
  };
};

export const useFetchNFT = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();

  useEffect(() => {
    if (account) {
      dispatch(fetchNFTUser(account));
    }
  }, [account, dispatch]);
};

export const useFetchConfig = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const whitelistId = params.get("id");
  const statewhitelistId = params.get("state");
  const { fastRefresh } = useRefresh();

  useMemo(() => {
    if (account) {
      dispatch(fetchConfig(whitelistId || statewhitelistId || "", account));
    } else {
      dispatch(fetchConfig(whitelistId || statewhitelistId || ""));
    }
  }, [account, dispatch, fastRefresh, whitelistId, statewhitelistId]);
};

export const useFetchRegisteredCount = () => {
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const whitelistId = params.get("id");
  const statewhitelistId = params.get("state");
  const { fastRefresh } = useRefresh();

  useMemo(() => {
    dispatch(fetchRegisteredCount(whitelistId || statewhitelistId || ""));
  }, [dispatch, fastRefresh, whitelistId, statewhitelistId]);
};

export const useFetchResultRaffle = () => {
  const { account } = useWeb3React();
  const dispatch = useDispatch();
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  const whitelistId = params.get("id");
  const statewhitelistId = params.get("state");
  const { fastRefresh } = useRefresh();

  useMemo(() => {
    dispatch(
      fetchResultRaffle(whitelistId || statewhitelistId || "", account || "")
    );
  }, [dispatch, fastRefresh, whitelistId, statewhitelistId, account]);
};

export const useLanguage = (local: string) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchlanguage(local));
  }, [dispatch]);
};
