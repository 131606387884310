import { AbiItem } from "web3-utils";
import { Interface } from "@ethersproject/abi";
import { getWeb3 } from "./web3";
import MultiCallAbi from "../config/abi/Multicall.json";

export interface Call {
  address: string;
  name: string;
  params?: any[];
}

const multicall = async (abi: any[], calls: Call[]) => {
  const web3 = getWeb3();
  const multi = new web3.eth.Contract(
    MultiCallAbi as unknown as AbiItem,
    process.env.REACT_APP_MULTICALL_CONTRACT_ADDRESS
  );
  const itf = new Interface(abi);

  const calldata = calls.map((call: any) => [
    call.address.toLowerCase(),
    itf.encodeFunctionData(call.name, call.params),
  ]);
  const { returnData } = await multi.methods.aggregate(calldata).call();
  const res = returnData.map((call: any, i: number) =>
    itf.decodeFunctionResult(calls[i].name, call)
  );

  return res;
};

export const multicallEth = async (account: string) => {
  const web3 = getWeb3();
  const multi = new web3.eth.Contract(
    MultiCallAbi as unknown as AbiItem,
    process.env.REACT_APP_MULTICALL_CONTRACT_ADDRESS
  );
  const response = await multi.methods.getEthBalance(account).call();
  return response;
};

export default multicall;
