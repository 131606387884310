import Web3 from "web3";
/**
 * Provides a web3 instance using our own private provider httpProver
 */
const getWeb3 = () => {
  const web3 = new Web3(
    window.ethereum ||
      "https://bsc-dataseed1.ninicoin.io" ||
      "https://bsc-dataseed1.defibit.io" ||
      "https://bsc-dataseed.binance.org"
  );
  return web3;
};

window.web3 = new Web3(
  window.ethereum ||
    "https://bsc-dataseed1.ninicoin.io" ||
    "https://bsc-dataseed1.defibit.io" ||
    "https://bsc-dataseed.binance.org"
);
const { web3 } = window;

export { getWeb3, web3 };
