import { configureStore } from "@reduxjs/toolkit";
import walletReducer from "./wallet";
import languageReducer from "./language";
import configSlice from "./config";

export default configureStore({
  devTools: process.env.NODE_ENV === "production",
  reducer: {
    wallet: walletReducer,
    language: languageReducer,
    config: configSlice
  }
});
