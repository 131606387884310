/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import { LanguageState } from "../types";
import { Dispatch } from "redux";
import i18nConfig from "../../config/i18n";
import gaia from "../../gaia/gaia";

const initialState: LanguageState = {
  locale: "en",
  isLocaleDetermined: false
};

export const languageSlice = createSlice({
  name: "Language",
  initialState,
  reducers: {
    setLocale: (state, action) => {
      const { locale } = action.payload;
      state.locale = locale;
    },
    setIsLocaleDetermined: (state, action) => {
      const { isLocaleDetermined } = action.payload;
      state.isLocaleDetermined = isLocaleDetermined;
    }
  }
});

// Actions
export const { setLocale, setIsLocaleDetermined } = languageSlice.actions;

export const setLanguage = (locale: string) => async (dispatch: Dispatch) => {
  return dispatch(setLocale({ locale: locale }));
};
export const setDetermined =
  (isLocaleDetermined: boolean) => async (dispatch: Dispatch) => {
    return dispatch(
      setIsLocaleDetermined({ isLocaleDetermined: isLocaleDetermined })
    );
  };

export const fetchlanguage = (local: string) => async (dispatch: Dispatch) => {
  const { supportedLocales, fallbackLocale } = i18nConfig;

  gaia
    .init({
      supportedLocales: Object.keys(supportedLocales),
      locale: local,
      fallbackLocale
    })
    .then((lo: any) => {
      dispatch(setLocale({ locale: lo }));
      dispatch(setIsLocaleDetermined({ isLocaleDetermined: true }));
    })
    .catch(() => {
      dispatch(setLocale({ locale: local }));
      dispatch(setIsLocaleDetermined({ isLocaleDetermined: true }));
    });
};

export default languageSlice.reducer;
