import binanceLogoUrl from "../assets/images/binance-logo.png";
import logoMetamask from "../assets/logo/logo-metamask.png";
import logoKaikas from "../assets/logo/logo-kaikas.png";
import { injectedVerify } from "../connectors";

const chainId =
  process.env.REACT_APP_CHAIN_ID === process.env.REACT_APP_MAINNET_ID
    ? process.env.REACT_APP_MAINNET_ID
    : process.env.REACT_APP_TESTNET_ID;

const chainBinanceId =
  process.env.REACT_APP_BINANCE_CHAIN_ID ===
  process.env.REACT_APP_BINANCE_MAINNET_ID
    ? process.env.REACT_APP_BINANCE_MAINNET_ID
    : process.env.REACT_APP_BINANCE_TESTNET_ID;

export enum SupportedChainId {
  BINANCE = 56,
  KLAYTN = 8217
}

export enum NetworkType {
  L1,
  L2
}

export enum ErrorCode {
  SUCCESS = "V1001",
  TICKET_IS_USED = "V1002",
  INVALID_VERIFY = "V1003",
  YOU_ARE_REGISTERED = "V1004",
  WHITELIST_HAS_BEEN_FULL = "V1005",
  INVALID_WHITELIST_ID = "V1006",
  INVALID_TICKET = "V1007",
  INVALID_FLOW_ID = "V1008",
  INVALID_REQUEST_PARAM = "V1009",
  EVENT_NOT_START = "V1010",
  EVENT_HAS_ENDED = "V1011"
}

export const WALLETLIST = [
  {
    title: "Metamask",
    logo: logoMetamask,
    connectorId: "injected"
  },
  {
    title: "Kaikas",
    logo: logoKaikas,
    connectorId: "injected"
  }
];

export const WALLETVERIFY = [
  {
    name: "Metamask",
    logo: logoMetamask,
    connectorId: "injected",
    connector: injectedVerify
  }
];

export const FILTERED = [
  {
    docs: "https://docs.uniswap.org/",
    explorer: "https://etherscan.io/",
    infoLink: "https://info.uniswap.org/#/",
    label: "Binance",
    logoUrl: binanceLogoUrl,
    id: chainBinanceId
  },
  {
    docs: "https://docs.uniswap.org/",
    explorer: "https://etherscan.io/",
    infoLink: "https://info.uniswap.org/#/",
    label: "Klaytn",
    logoUrl: logoKaikas,
    id: chainId
  }
];

export const WALLETNAME = {
  METAMASK: {
    name: "Metamask"
  },
  KAIKAS: {
    name: "Kaikas"
  }
};

export const FLOW = {
  TOKEN: 1,
  SUNMIYA: 2,
  TARA: 3,
  VFINIX: 4,
  TICKET: 5
};

export enum EVENT {
  token = 1,
  sunmiya = 2,
  "t-ara" = 3,
  vFinix = 4,
  ticket = 5
}