import React, { lazy, Suspense } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import GlobalStyle from "./style/Global";
import { DAppProvider } from "@usedapp/core";
import Loading from "./uikit-dev/components/Loading/index";
import {
  useFetchNFT,
  useLanguage,
  useFetchConfig,
  useFetchRegisteredCount,
  useFetchResultRaffle,
} from "./state/hooks";

// const Home = lazy(() => import("./views/Home"));
const Wallet = lazy(() => import("./views/Wallet"));
// const Courses = lazy(() => import("./views/Courses"));
// const SetupKeplrCourse = lazy(() => import("./views/SetupKeplrCourse"));
// const Registration = lazy(() => import("./views/Registration"));
// const Claim = lazy(() => import("./views/Claim"));
const Airdrop = lazy(() => import("./views/Airdrop"));
const Verify = lazy(() => import("./views/Verify"));
const Event = lazy(() => import("./views/Event"));
const NotFound = lazy(() => import("./views/NotFound"));
const Banner = lazy(() => import("./uikit-dev/components/Banner/index"));
const Header = lazy(() => import("./uikit-dev/components/Header/index"));
const Footer = lazy(() => import("./uikit-dev/components/Footer/index"));

const App: React.FC = () => {
  useFetchNFT();
  useLanguage("en");
  useFetchConfig();
  useFetchRegisteredCount();
  useFetchResultRaffle();
  return (
    <Router>
      <DAppProvider config={{}}>
        <GlobalStyle />
        <Suspense fallback={<Loading />}>
          <Header />
          <Banner />
          <Switch>
            {/* <Route path="/" exact>
              <Home />
            </Route> */}
            <Route path="/" exact>
              <Wallet />
            </Route>
            {/* <Route path="/courses" exact>
              <Courses />
            </Route>
            <Route path="/courses/setup-keplr-courses">
              <SetupKeplrCourse />
            </Route>
            <Route path="/courses/registration">
              <Registration />
            </Route>
            <Route path="/courses/claim">
              <Claim />
            </Route> */}
            <Route path="/airdrop">
              <Airdrop />
            </Route>
            {/* <Route path="/airdrop/:whitelistId">
              <Airdrop />
            </Route> */}
            <Route path="/verify">
              <Verify />
            </Route>
            <Route
              path="/whitelist/(token|sunmiya|t-ara|vFinix|ticket)/:whitelist/:id?"
              exact
            >
              <Event />
            </Route>
            <Route component={NotFound} />
          </Switch>
          <Footer />
        </Suspense>
      </DAppProvider>
    </Router>
  );
};

export default App;
