/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import BigNumber from "bignumber.js";
import { WalletState } from "../types";
import { Dispatch } from "redux";
import { chain } from "lodash";
import * as NFTListData from "../../nft.json";
import { NFTData } from "../types";
import dryotusABI from "../../config/abi/dryotus.json";
import multicall from "../../utils/multicall";
export interface Owning {
  [key: string]: number;
}

export interface TokenId {
  tokenId: [];
}

const initialState: WalletState = {
  disconnect: false,
  account: "",
  nftListData: NFTListData.data,
  owning: "",
  chainId: process.env.REACT_APP_BINANCE_CHAIN_ID || "",
  openDialogWallet: false
};

export const walletSlice = createSlice({
  name: "Wallet",
  initialState,
  reducers: {
    setDisconnect: (state, action) => {
      const { disconnect } = action.payload;
      state.disconnect = disconnect;
    },
    setOpenDialogWallet: (state, action) => {
      const { openDialogWallet } = action.payload;
      state.openDialogWallet = openDialogWallet;
    },
    setAccount: (state, action) => {
      const { account } = action.payload;
      state.account = account;
    },
    setChainId: (state, action) => {
      const { chainId } = action.payload;
      state.chainId = chainId;
    },
    setUserNFTs: (state, { payload }) => {
      // eslint-disable-next-line no-param-reassign
      state.nftListData = state.nftListData.map((nft: NFTData) => {
        const filteredKey = Object.keys(payload).filter(
          (n: string) =>
            parseInt(n, 10) >= nft.startID && parseInt(n, 10) <= nft.endID
        );
        const result: NFTData = {
          ...nft,
          userData: {
            amountOwn: filteredKey.length,
            owning: filteredKey.map((n: string) => parseInt(n, 10))
          }
        };
        return result;
      });
    },
    setOnwing: (state, action) => {
      const { owning } = action.payload;
      state.owning = owning;
    }
  }
});

// Actions
export const {
  setDisconnect,
  setAccount,
  setUserNFTs,
  setOnwing,
  setChainId,
  setOpenDialogWallet
} = walletSlice.actions;

export const setUserDisconnect =
  (disconnect: boolean) => async (dispatch: Dispatch) => {
    return dispatch(
      setDisconnect({
        disconnect: disconnect
      })
    );
  };

export const setCurrentAccount = (account: string) => async (dispatch: any) => {
  return dispatch(setAccount({ account: account }));
};

export const setAccChainId = (chainId: string) => async (dispatch: any) => {
  return dispatch(setChainId({ chainId: chainId }));
};

export const setDialogWallet = (show: boolean) => async (dispatch: any) => {
  return dispatch(setOpenDialogWallet({ openDialogWallet: show }));
};

export const fetchNFTUser =
  (account: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    const owningData: Owning = {};
    const groupedNFTS = chain(NFTListData.data).groupBy("name").value();
    await Promise.all(
      Object.keys(groupedNFTS).map(async (key: string) => {
        const NFTGroup: NFTData[] = groupedNFTS[key];
        const calls = NFTGroup.map((nftConfig: NFTData) => {
          const idToCall = Array.from(
            Array(nftConfig.endID - nftConfig.startID + 1).keys()
          ).map((i: number) => nftConfig.startID + i);
          const addressToCall = Array(
            nftConfig.endID - nftConfig.startID + 1
          ).fill(account);
          return {
            address: process.env.REACT_APP_NFT_CONTRACT_ADDRESS || "",
            name: "balanceOfBatch",
            params: [addressToCall, idToCall]
          };
        });
        const allUserNFTSData = await multicall(dryotusABI, calls);
        NFTGroup.forEach((nftConfig: NFTData, index: number) => {
          const [fetchedOwningData] = allUserNFTSData[index];
          fetchedOwningData.forEach((itemOwning: any, itemIndex: number) => {
            // eslint-disable-next-line no-underscore-dangle
            const currentOwning = new BigNumber(itemOwning._hex).isEqualTo(1);
            if (currentOwning) {
              owningData[itemIndex + nftConfig.startID] = 1;
            }
          });
        });
        return true;
      })
    );
    dispatch(setOnwing({ owning: Object.keys(owningData)[0] }));
  };

export default walletSlice.reducer;
